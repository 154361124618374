import classNames from "classnames";

const TableTag = ({ children, className = "", ...props }) => {
  return (
    <div className="table-wrap">
      <table
        className={classNames("table richtext-table", className)}
        {...props}
      >
        {children.filter((child) => child !== "\n")}
      </table>
    </div>
  );
};

export default TableTag;
