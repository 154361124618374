import React, { useEffect } from "react";
import Router, { useRouter } from "next/router";
import App from "next/app";
import { ApolloProvider } from "@apollo/client";
import Script from "next/script";
import Head from "next/head";
import { init as initApm } from "@elastic/apm-rum";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";

import IntlWrapper from "../modules/i18n/components/IntlWrapper";
import localizations from "../i18n";

import CurrentPageWrapper from "../modules/navigation/components/CurrentPageWrapper";
import ScrollPositionProvider from "../modules/navigation/components/ScrollPositionProvider";
import * as matomo from "../modules/analytics/utils/matomo";
import ToastProvider from "../modules/toast/utils/toast-provider";
import Popup from "../modules/layout/components/Popup";
import { CurrentClientQuery } from "../modules/auth/hooks/useCurrentClient";
import { useApollo } from "../modules/apollo/apolloClient";

import "normalize.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "../public/static/css/all.css";
import CockpitContextProvider from "modules/getcockpit/components/CockpitContext";
import customCockpitComponents from "modules/layout/custom-cockpit-components";

// eslint-disable-next-line no-console
console.log({
  env: process.env.NODE_ENV,
  version: process.env.publicareVersion,
});

if (
  process.env.NEXT_PUBLIC_ELASTIC_URL &&
  process.env.NEXT_PUBLIC_ELASTIC_ENV
) {
  initApm({
    serverUrl: process.env.NEXT_PUBLIC_ELASTIC_URL,
    serviceName: "publicare-storefront",
    transactionSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_ELASTIC_ENV,
    serviceVersion: process.env.publicareVersion,
    distributedTracingOrigins: [
      "dev.publicare.ch",
      "demo.publicare.ch",
      "test.publicare.ch",
      "publicare.ch",
      "engine.dev.publicare.ch",
      "engine.test.publicare.ch",
      "engine.demo.publicare.ch",
      "engine.publicare.ch",
      "cms.publicare.ch",
    ],
  });
}

const handleRouteChange = (apollo) => async (url) => {
  const { data = {} } = await apollo.query({
    query: CurrentClientQuery,
    errorPolicy: "none",
  });
  const clientId = data?.me?.currentClient?._id;
  const username = data?.me?.username;
  matomo.pageview(document.location.origin + url, document.title, {
    username,
    clientId,
  });
};

const MyApp = ({ Component, pageProps, err, messages, ...rest }) => {
  const router = useRouter();
  const routerLocale = rest.router.locale || router.locale;
  const locale =
    routerLocale === process.env.NEXT_PUBLIC_FAKE_LOCALE
      ? process.env.NEXT_PUBLIC_DEFAULT_LOCALE
      : routerLocale;
  const apollo = useApollo(pageProps, { locale });
  const modifiedPageProps = { ...pageProps, err };

  useEffect(() => {
    Router.events.on("routeChangeComplete", handleRouteChange(apollo));
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange(apollo));
    };
  }, []);
  return (
    <>
      <Head>
        <link sizes="180x180" href="/static/apple-touch-icon.png" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/favicon-16x16.png"
        />
        <link href="/static/safari-pinned-tab.svg" color="black" />
      </Head>

      <Script
        strategy="beforeInteractive"
        id="browser-support"
        dangerouslySetInnerHTML={{
          __html: `
      const translatedMessages = {
        de: "Ihr Webbrowser ist veraltet. Einige Funktionen können möglicherweise nicht richtig angezeigt werden. Klicken Sie hier für mehr Informationen und zum Herunterladen eines aktuellen Browsers.",
        fr: "Votre navigateur web est obsolète. Certaines fonctions peuvent ne pas être affichées correctement. Cliquez ici pour plus d'informations et pour télécharger un navigateur actuel.",
        it: "Il vostro browser web è obsoleto. Alcune funzioni potrebbero non essere visualizzate correttamente. Clicca qui per maggiori informazioni e per scaricare un browser aggiornato."
      }
      const userAgent = window.navigator.userAgent;
      const isBrowserSupported =   ${/((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(9[_.]3|9[_.]([4-9]|\d{2,})|([1-9]\d|\d{3,})[_.]\d+|12[_.]2|12[_.]([3-9]|\d{2,})|12[_.]4|12[_.]([5-9]|\d{2,})|(1[3-9]|[2-9]\d|\d{3,})[_.]\d+|13[_.]3|13[_.]([4-9]|\d{2,})|13[_.]7|13[_.]([8-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})[_.]\d+|14[_.]0|14[_.]([1-9]|\d{2,})|14[_.]4|14[_.]([5-9]|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})[_.]\d+)(?:[_.]\d+)?)|(CFNetwork\/8.* Darwin\/16\.5\.\d+)|(CFNetwork\/8.* Darwin\/16\.6\.\d+)|(CFNetwork\/8.* Darwin\/16\.7\.\d+)|(Opera Mini(?:\/att)?\/?(\d+)?(?:\.\d+)?(?:\.\d+)?)|(SamsungBrowser\/(7\.2|7\.([3-9]|\d{2,})|7\.4|7\.([5-9]|\d{2,})|([8-9]|\d{2,})\.\d+|13\.0|13\.([1-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})\.\d+))|(Edge\/(89(?:\.0)?|89(?:\.([1-9]|\d{2,}))?|(9\d|\d{3,})(?:\.\d+)?))|((Chromium|Chrome)\/(79\.0|79\.([1-9]|\d{2,})|([8-9]\d|\d{3,})\.\d+|83\.0|83\.([1-9]|\d{2,})|(8[4-9]|9\d|\d{3,})\.\d+)(?:\.\d+)?)|(Version\/(11\.1|11\.([2-9]|\d{2,})|(1[2-9]|[2-9]\d|\d{3,})\.\d+|13\.0|13\.([1-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})\.\d+|14\.0|14\.([1-9]|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})\.\d+)(?:\.\d+)? Safari\/)|(Firefox\/(78\.0|78\.([1-9]|\d{2,})|(79|[8-9]\d|\d{3,})\.\d+)\.\d+)|(Firefox\/(78\.0|78\.([1-9]|\d{2,})|(79|[8-9]\d|\d{3,})\.\d+)(pre|[ab]\d+[a-z]*)?)/}
      const outerDiv = document.getElementById('browser-not-supported');
      const innerLink = document.getElementById('browser-not-supported-link');
      if(!isBrowserSupported.test(userAgent)) {
        outerDiv.style.display = "block"
        innerLink.innerText = translatedMessages[window.navigator.language.split('-')[0]] || translatedMessages['de']
      }
    `,
        }}
      />

      <Script
        id="mailxpert"
        strategy="beforeInteractive"
        src="https://api.mailxpert.ch/forms.js"
      />

      {process.env.NEXT_PUBLIC_COOKIEBOT_ID ? (
        <Script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={process.env.NEXT_PUBLIC_COOKIEBOT_ID}
          data-blockingmode="auto"
        />
      ) : null}

      {process.env.NEXT_PUBLIC_RECAPTCHA_KEY ? (
        <Script
          id="recaptcha"
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
        />
      ) : null}
      {process.env.NEXT_PUBLIC_MATOMO_SITE_ID ? (
        <>
          <Script
            id="matomo-config"
            dangerouslySetInnerHTML={{
              __html: `
var _paq = window._paq = window._paq || [];
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
_paq.push(["setDoNotTrack", true]);
_paq.push(['setTrackerUrl', '${process.env.NEXT_PUBLIC_MATOMO_URL}/matomo.php']);
_paq.push(['setSiteId', '${process.env.NEXT_PUBLIC_MATOMO_SITE_ID}']);
`,
            }}
          />
          <Script
            id="matomo-script"
            src={`${process.env.NEXT_PUBLIC_MATOMO_URL}/matomo.js`}
          />
        </>
      ) : null}

      <CockpitContextProvider customComponents={customCockpitComponents}>
        <IntlWrapper
          locale={locale}
          messages={localizations?.[locale] || {}}
          key="intl-provider"
        >
          <ApolloProvider client={apollo}>
            <ScrollPositionProvider>
              <Popup />
              <ToastProvider>
                <ProgressBar
                  height="4px"
                  color="#afca0B"
                  options={{ showSpinner: false }}
                  shallowRouting
                />
                <CurrentPageWrapper
                  locale={locale}
                  component={Component}
                  {...modifiedPageProps}
                />
              </ToastProvider>
            </ScrollPositionProvider>
          </ApolloProvider>
        </IntlWrapper>
      </CockpitContextProvider>
    </>
  );
};

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default MyApp;
