import ImageWithFallback from "modules/common/components/ImageWithFallback";
import Link from "next/link";

const isAbsoluteUrl = (url) => {
  try {
    return new URL(url).origin !== "null";
  } catch (e) {
    return false;
  }
};

const ImageComponent = ({ data, label }) => {
  const { url } = data;
  const imageElement = (
    <ImageWithFallback
      className="mt-0 force-position-relative"
      src={data?.asset?.path}
      alt={data?.asset?.description || data?.asset?.title}
      quality={80}
      fill
      sizes="100vw"
      style={{
        objectFit: "contain",
      }}
    />
  );

  return (
    <figure className={`m-0 ${data?.class || ""}`}>
      {url && isAbsoluteUrl(url) ? (
        <Link href={url}>{imageElement}</Link>
      ) : (
        imageElement
      )}
      <figcaption className="d-none">{label}</figcaption>
    </figure>
  );
};

export default ImageComponent;
