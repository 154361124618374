import classNames from "classnames";

const TBodyTag = ({ children, className = "", ...props }) => {
  return (
    <tbody className={classNames("tbody", className)} {...props}>
      {children.filter((child) => child !== "\n")}
    </tbody>
  );
};

export default TBodyTag;
