import { ApolloClient, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import apolloCache, { resetCache } from "./apolloCache";
import { generatePersistedQueryIdsFromManifest } from "@apollo/persisted-query-lists";

const allowedHeaders = [
  "accept-language",
  "pragma",
  "user-agent",
  "x-forwarded-for",
  "x-forwarded-host",
  "x-forwarded-port",
  "x-forwarded-proto",
  "x-forwarded-server",
  "x-mapihttpcapability",
  "x-real-ip",
  "x-user-identity",
  "apollo-require-preflight",
  "host",
  "cookie",
  "authorization",
];

const setLocale = (headers, locale) => {
  const newHeaders = {
    ...Object.fromEntries(
      Object.entries(headers).filter(([key]) => {
        return allowedHeaders.includes(key.toLowerCase());
      }),
    ),
    "accept-language": locale,
  };
  return { headers: newHeaders };
};

const getApolloClient = ({ headers = {}, locale }) => {
  const uri =
    typeof window === "undefined"
      ? process.env.UNCHAINED_ENDPOINT
      : process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT;

  const localeLink = setContext((_, { headers: contextHeaders = {} }) => {
    return setLocale(
      { ...headers, ...contextHeaders, "apollo-require-preflight": 1 },
      locale,
    );
  });

  const persistedQueryLink = createPersistedQueryLink({
    ...generatePersistedQueryIdsFromManifest({
      loadManifest: async () => {
        const manifest = await import("../../../storefront-queries.json");
        const operations = Object.entries(manifest.default).map(
          ([name, id]) => ({
            id,
            name,
          }),
        );
        return { operations };
      },
    }),
    useGETForHashedQueries: true,
  });

  const httpLink = createUploadLink({
    uri,
    credentials: "same-origin",
    fetch,
    headers:
      typeof window === "undefined"
        ? {
            "X-Unlock-API": process.env.PERSISTED_QUERIES_KEY,
          }
        : undefined,
    ssrMode: typeof window === "undefined",
    includeExtensions: true,
  });

  const cache = apolloCache(locale);
  const apolloClient = new ApolloClient({
    connectToDevTools: typeof window !== "undefined",
    ssrMode: typeof window === "undefined",
    link: process.env.PERSISTED_QUERIES_KEY
      ? ApolloLink.from([localeLink, httpLink])
      : ApolloLink.from([persistedQueryLink, localeLink, httpLink]),
    assumeImmutableResults: true,
    cache,
  });

  apolloClient.onResetStore(async () => {
    return resetCache(cache);
  });

  return apolloClient;
};

export default getApolloClient;
